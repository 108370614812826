"use strict";
exports.__esModule = true;
var Vibrant = require("node-vibrant");
var empty = "";
function setPrimaryButtonColor(s) {
    setColorByClassName("btn-primary", s.getBodyTextColor(), s.getHex());
}
function setSecondaryButtonColor(s) {
    setColorByClassName("btn-secondary", s.getBodyTextColor(), s.getHex());
}
function setHyperlinkColor(swatch) {
    var elements = document.getElementsByTagName("a");
    var array = Array.from(elements);
    array.forEach(function (element) {
        if (element.classList.length < 1) // only set color of "pure" hyperlinks, no buttons
            setColorForHTMLElement(element, swatch.getHex(), empty);
    });
}
function setContact(s) {
    setColorByClassName("contact", empty, s.getHex());
}
function setSocialLink(s) {
    setColorByClassName("social-link", empty, s.getHex());
}
function setPortfolioItem(s) {
    setColorByClassName("caption", empty, "rgba(0,0,0,0.4)"); //"rgba(" + s.getRgb()[0] + "," + s.getRgb()[1] + "," + s.getRgb()[2] + ",0.5)");
}
function setSideBarWrapper(s) {
    setColorById("sidebar-wrapper", empty, s.getHex());
}
function setDropDownMenu(s) {
    setColorByClassName("dropdown-menu", empty, s.getHex());
}
function setColors(palette) {
    setSecondaryButtonColor(palette.LightVibrant);
    setPortfolioItem(palette.DarkVibrant);
    setHyperlinkColor(palette.DarkVibrant);
    setPrimaryButtonColor(palette.DarkMuted);
    setSideBarWrapper(palette.DarkMuted);
    setDropDownMenu(palette.DarkMuted);
    setContact(palette.DarkMuted);
    setSocialLink(palette.DarkMuted);
}
function setColorById(elementName, color, backgroundColor) {
    var element = document.getElementById(elementName);
    setColorForHTMLElement(element, color, backgroundColor);
}
function setColorByTagName(elementName, color, backgroundColor) {
    var elements = document.getElementsByTagName(elementName);
    var array = Array.from(elements);
    array.forEach(function (element) { setColorForHTMLElement(element, color, backgroundColor); });
}
function setColorByClassName(elementName, color, backgroundColor) {
    var elements = document.getElementsByClassName(elementName);
    var array = Array.from(elements);
    array.forEach(function (element) { setColorForHTMLElement(element, color, backgroundColor); });
}
function setColorForHTMLElement(element, color, backgroundColor) {
    if (element instanceof HTMLElement) {
        var e = element;
        if (color != empty)
            e.style.color = color;
        if (backgroundColor != empty) {
            e.style.backgroundColor = backgroundColor;
            e.style.borderColor = backgroundColor;
        }
        console.log("set: " + e.innerHTML + " to: " + backgroundColor);
    }
}
Vibrant.from('img/portfolio-5.jpg').getPalette(function (err, palette) {
    console.log(palette);
}).then(function (p) { return setColors(p); });
